<template>
	<div class="gg-container">
		
		<div class="search-container">
			<div class="search-container-fn-input">
				<label>input01：</label>
				<el-input style="width: 200px" size="small" placeholder="input01" prefix-icon="el-icon-search" clearable
									@change="getList('restPage')" v-model="searchParams.input01">
				</el-input>
			</div>
			
			<div class="search-container-fn-input">
				<label>级联01：</label>
				<el-cascader v-model="searchParams.cascader" style="width: 200px" clearable size="small"
										 :options="options01" @change="getList('restPage')" :props="{ checkStrictly: true }">
				</el-cascader>
			</div>
			<div class="search-container-fn-input">
				<label>select01：</label>
				<el-select style="width: 200px" @change="getList" v-model="searchParams.select01" clearable
									 placeholder="请选择" size="small">
					<el-option v-for="item in options" :key="item.value" :label="item.label"
										 :value="item.value">
					</el-option>
				</el-select>
			</div>
			<div class="search-container-fn-input">
				<label>时间：</label>
				<el-date-picker
					size="mini"
					v-model="searchParams.created_at"
					type="daterange"
					range-separator="至"
					start-placeholder="开始日期"
					end-placeholder="结束日期">
				</el-date-picker>
			</div>
			<!--按钮-->
			<div class="search-container-fn-input" style="margin-left: auto">
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handlebtn01('新增')">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">新增</span>
					</el-button>
				</div>
				
				<!--对应第三方图标的button样式-->
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="success"
						style="padding: 6px 7px 7px 8px"
						@click="handlebtn02(scope.$index, scope.row)">
						<i class="iconfont icon-shangchuan" style="font-size: 13px"/>
						<span style="margin-left: 5px;font-size: 12px">上传</span>
					</el-button>
				</div>
			</div>
		</div>
		
		
		<div class="search-container">
			<div class="search-container-fn-input">
				<label>select03：</label>
				<el-select style="width: 200px" v-model="searchParams.upload_status" clearable placeholder="请选择"
									 size="small">
					<template v-for="item in options">
						<el-option :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</template>
				</el-select>
			</div>
		</div>
		
		
		<!---------------样式2----------------->
		<div style="margin: 20px">样式2</div>
		<div class="search-container">
			<div
				style="min-width: 150px;height: 70px; border-radius: 10px; background-color: #4EBBFC; margin-right: 20px;display: flex; justify-content: space-around; align-items: center">
				<span style="color: #fff; font-weight: 500; font-size: 14px">价格</span>
				<span style="color: #fff; font-weight: 500; font-size: 14px">¥999</span>
			</div>
			<div
				style="min-width: 150px;height: 70px; border-radius: 10px; background-color: #FCC810;display: flex; justify-content: space-around; align-items: center">
				<span style="color: #fff; font-weight: 500; font-size: 14px">完成量</span>
				<span style="color: #fff; font-weight: 500; font-size: 14px">999</span>
			</div>
			
			<div class="search-container-fn-input" style="margin-left: auto">
				<div class="search-container-fn-input">
					<label>input01：</label>
					<el-input style="width: 200px" size="small" placeholder="input01" prefix-icon="el-icon-search" clearable
										@change="getList('restPage')" v-model="searchParams.input01">
					</el-input>
				</div>
				<div class="search-container-fn-input">
					<label>select02：</label>
					<el-select style="width: 200px" v-model="searchParams.status" clearable placeholder="请选择" size="small">
						<template v-for="item in options">
							<el-option :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</template>
					</el-select>
				</div>
				
				<div class="search-container-fn-input">
					<el-button
						size="mini"
						type="primary"
						style="padding: 6px 7px 7px 8px; background-color: #2362FB; border-color: #2362FB"
						@click="handlebtn01('新增')">
						<i class="el-icon-plus" style="font-size: 13px"/>
						<span style="margin-left: 5px; font-size: 12px">编辑</span>
					</el-button>
				</div>
			</div>
		</div>
	
		<!---------------导入富文本------------->
		<div style="margin: 20px">富文本</div>
		<tinymce v-model="content" :height="300"/>
		
		
	</div>
</template>

<script>
import Tinymce from '@/components/Tinymce'
export default {
  name: "MultipleInputAndOther",
  components: {
    Tinymce,
	},
  created () {
  },
  data () {
    return {
      searchParams: {},
      listQuery: {
        page: 1,
        limit: 10,
        importance: undefined,
        title: undefined,
        type: undefined,
        sort: '+id',
      },
      total: 0,
      listLoading: false,
      options: [],
      options01: [],
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
			content: '',
    }
  },
  methods: {
    test () {
    },
    getList (type) {
      if (type == 'restPage') {
        this.listQuery.page = 1
      }
      this.listLoading = true
      this.searchParams.page = this.listQuery.page
      this.searchParams.limit = this.listQuery.limit
      this.test(this.searchParams).then(response => {
        let data = response
        if (data.code == 200) {
          this.getPayCount()
          let _data = data.data
          this.total = _data.total
          this.listQuery.limit = _data.per_page
          this.tableData = _data.list
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.listLoading = false
      })
    },
    changeExamine (row) {  //选择状态 (启用/禁用)
      console.log(row.appraise_id)
      console.log(row.status)
      this.listLoading = true
      this.test().then(response => {
        if (response.code === 200) {
          this.$message({
            type: 'success',
            message: '修改成功!'
          })
        } else {
          this.$message({
            type: 'error',
            message: response.message
          })
        }
        this.listLoading = false
      }).catch(error => {
        console.log(error);
        this.$message({
          type: 'error',
          message: '修改失败!'
        })
        this.listLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
